<template>
  <div class="home">
    <div class="main" style="height: auto">
      <el-button @click="$router.push('/')" type="text">
        <span v-if="$i18n.locale != 'en-us'">返回</span>
        <span v-else>Back</span>
      </el-button>
    </div>
    <div class="flex show main jsb">
      <div class="left">
        <img src="../../assets/image/icon/left.png" class="lImg" alt="" @click="changeSub" />
        <img src="../../assets/image/icon/right.png" class="rImg" alt="" @click="changeAdd" />
        <el-card>
          <div class="img flex jsc alc">
            <viewer :images="detail.imageUrl && detail.imageUrl.split('&')">
              <img :src="detail.imageUrl && detail.imageUrl.split('&')[infoAct]" />
            </viewer>
          </div>

          <div class="flex jsb left-footer">
            <div>
              <span v-html="detail.trivialName" dd="请输入大类-对应类别门或纲" v-if="$i18n.locale != 'en-us'"></span>
              <span v-html="detail.trivialNameEn" dd="请输入大类" v-else></span>
            </div>
            <div>
              <span>
                {{ infoAct + 1 }}/ {{ detail.imageUrl && detail.imageUrl.split("&").length }}
                <span v-if="$i18n.locale != 'en-us'">张</span>
                <span v-else>page</span>
              </span>
            </div>
            <favorite :topicId="$route.query.id" :type="1"></favorite>
          </div>
        </el-card>
      </div>
      <div class="right flex jsb wrap">
        <el-card>
          <div class="infoList flex wrap jsb">
            <div v-for="(item, index) in detail.imageUrl && detail.imageUrl.split('&')">
              <img :class="{ imgAct: infoAct == index }" :src="item" alt="" @click="infoAct = index" :key="index" />
            </div>
            <div style="width: 112px"></div>
          </div>
        </el-card>
        <el-card>
          <div class="title" style="padding: 0 20px">
            <span v-if="$i18n.locale != 'en-us'">相似推荐</span>
            <span v-else>Similar to recommend</span>
          </div>
          <div class="infoList flex wrap jsb">
            <div v-for="(item, index) in allList">
              <img :class="{ imgAct: allAct == index }" :src="item.thumbnailUrl" alt="" :key="index"
                @click="goInfo(item)" />
            </div>
            <div v-if="allList.length == 0">暂无数据</div>

            <div style="width: 112px"></div>
          </div>
        </el-card>
      </div>
    </div>

    <div class="main info flex jsb">
      <div class="left">
        <el-card>
          <div class="gbdb">
            <InfoDetail :detail="detail" :type="2"></InfoDetail>
            <!-- <div class="title">文献系统推荐</div> -->
            <div class="title">
              <span>{{ $t("details.ReferenceRecommend") }}</span>
            </div>
            <references v-if="detail && detail.trivialNameEn" :name="detail && detail.trivialNameEn"></references>
          </div>
        </el-card>
      </div>
      <div class="right">
        <el-card>
          <div class="gbdb">
            <div class="title">
              GBDB
              <span v-if="$i18n.locale != 'en-us'">推荐 </span>
              <span v-else>recommend</span>
            </div>
            <GBDB v-if="detail && detail.trivialNameEn" :name="detail && detail.trivialNameEn"></GBDB>
          </div>
        </el-card>
      </div>
    </div>

    <div class="main" ref="comment" id="target">
      <comment :topicId="$route.query.id" :type="1"></comment>
    </div>
  </div>
</template>

<script>
import ImageApi from "@/api/image/index";
import commonApi from "@/api/common/index";
import QRCode from "qrcodejs2";

export default {
  data () {
    return {
      infoAct: 0,
      allAct: 0,
      detail: {},
      allList: [],
      form: {},
    };
  },
  created () { },
  mounted () {
    this.init(this.$route.query.id);
    this.$nextTick(() => {
      this.qrcode = new QRCode("qrcode", {
        width: 170,
        height: 170, // 高度
        text: "默认内容", // 二维码内容
        // colorDark: "#23420f",
      });
    });
  },
  watch: {
    $route (val) {
      if (val.query.id) {
        this.init(this.$route.query.id);
        this.infoAct = 0;
      }
    },
  },
  methods: {
    changeSub () {
      if (this.infoAct == 0) {
        return;
      }
      this.infoAct--;
    },
    changeAdd () {
      if (this.infoAct == this.detail.imageUrl.split("&").length - 1) {
        this.goInfo(this.allList[this.allAct + 1]);
        return;
      }
      this.infoAct++;
    },
    async init (id) {
      const { data: res } = await ImageApi.getImgInfo(id);
      this.detail = res;
      let arr = [
        this.detail.trivialName,
        this.detail.trivialNameEn,
        this.detail.phylum,
        this.detail.phylumEn,
        this.detail.classA,
        this.detail.classEn,
        this.detail.subclass,
        this.detail.subclassEn,
        this.detail.infraclass,
        this.detail.infraclassEn,
        this.detail.superorder,
        this.detail.superorderEn,
        this.detail.orderEn,
        this.detail.orderA,
        this.detail.suborder,
        this.detail.suborderEn,
        this.detail.infraorder,
        this.detail.infraorderEn,
        this.detail.superfamily,
        this.detail.superfamilyEn,
        this.detail.family,
        this.detail.familyEn,
        this.detail.subfamily,
        this.detail.subfamilyEn,
        this.detail.genus,
        this.detail.genusEn,
        this.detail.species,
        this.detail.speciesEn,
      ];
      arr = arr.filter((n) => n);
      const { data: res1 } = await commonApi.recommendSearch({
        category: arr,
        range: "info",
        pageNum: 1,
        pageSize: 30,
      });
      this.allList = res1.rows;
      this.allAct = this.allList.findIndex((el) => {
        return this.$route.query.id == el.topicId;
      });
    },
    goInfo (item) {
      this.$router.push({
        path: "imgInfo",
        query: {
          id: item.topicId,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 0 !important;
}

.form {
  .el-form-item {
    display: flex;
    font-size: 16px !important;

    width: 220px;

    ::v-deep .el-form-item__label {
      font-weight: 500;
    }

    ::v-deep .el-form-item__content {
      font-size: 12px !important;
    }
  }

  ::v-deep input {
    border: none;
  }
}

.home {
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding-top: 30px;
  height: auto !important;
  padding-bottom: 50px;

  .title {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0;
  }

  .show {
    height: 1000px;

    .left {
      width: 70%;
      height: 100%;
      position: relative;

      .lImg {
        width: 60px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .rImg {
        width: 60px;
        cursor: pointer;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      .el-card {
        width: 100%;
        height: 100%;

        .img {
          width: 70%;
          margin: 5px auto;
          height: 900px;
          overflow: hidden;

          div {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
          }

          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
          }
        }

        .left-footer {
          width: 80%;
          margin: 20px auto;
        }
      }
    }

    .right {
      width: 25%;
      height: 100%;

      .el-card {
        width: 100%;
        height: 48%;

        ::v-deep .el-card__body {
          padding: 0 !important;
        }

        overflow-y: scroll;

        .infoList {
          padding: 20px;
          box-sizing: border-box;
          width: 100%;

          img {
            max-width: 135px;
            max-height: 100%;
            width: auto;
            height: auto;
            flex-shrink: 0;
            margin-bottom: 5px;
          }

          .imgAct {
            border: 2px #1890ff solid;
          }
        }
      }
    }
  }

  .info {
    margin-top: 20px;
    min-height: 700px;

    .left {
      width: 65%;
      height: 100%;

      .el-card {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      width: 30%;
      height: 100%;

      .el-card {
        width: 100%;
        height: 100%;

        .gbdb {
          width: 320px;
          height: 308px;
          overflow-y: scroll;
          margin: 0 auto;
        }

        .attr-box {
          width: 320px;
          height: 308px;
          background: #f1f1f1;
          border-radius: 4px;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
